<template>
  <sb-link
    v-if="blok.link"
    v-editable="blok"
    :link="blok.link"
    class="[data-pressed='true']:before:-translate-y-[40%] relative flex size-full flex-col overflow-hidden rounded-double border-2 border-text bg-back-accent p-6 text-start text-text transition-spacing duration-300 before:absolute before:left-0 before:top-0 before:size-20 before:-translate-x-1/2 before:-translate-y-1/2 before:rounded-full before:bg-primary before:transition-[width,height,transform,opacity] before:delay-[0,0,0,100ms] before:duration-300 hover:scale-105 hover:before:-translate-y-[40%] hover:before:scale-[5] focus:scale-105 focus:before:-translate-y-[40%] focus:before:scale-[5] active:scale-105 active:border-text-accent active:text-text-accent active:before:-translate-y-[40%] active:before:scale-[10] tablet:hover:scale-110 tablet:focus:scale-110 tablet:active:scale-110 [&[data-pressed='true']]:scale-105 [&[data-pressed='true']]:before:scale-[10] [&[data-pressed='true']]:before:opacity-0 tablet:[&[data-pressed='true']]:scale-110"
    :data-pressed="clicked"
    @click="clicked = true"
    @transitionend="clicked = false"
  >
    <span class="z-10 text-xl font-bold empty:hidden">{{ blok.headline }}</span>
    <span class="z-10 mt-1 text-xl font-thin empty:hidden">{{ blok.subline }}</span>

    <div class="z-10 mt-auto flex w-full shrink items-end justify-end">
      <nuxt-icon v-if="blok.icon" :name="blok?.icon.toString()" class="mr-auto text-2xl [&>svg]:!mb-0 [&>svg]:!w-auto" />
      <span role="presentation" class="inline-flex items-center pt-4 text-base font-semibold">
        {{ blok.linkText }}
        <nuxt-icon
          class="ml-2 grid aspect-square size-8 place-content-center rounded-full border-2 text-lg [&>svg]:!mb-0 [&>svg]:!ml-0.5"
          name="chevron-right"
          filled
        />
      </span>
    </div>
  </sb-link>
</template>

<script setup lang="ts">
import type { CardButtonStoryblok } from "~/storyblok-component-types"

defineProps<{ blok: CardButtonStoryblok; }>()

const clicked = ref(false)
</script>
